<template>
  <div class="pur-rec">
    <div class="center-title">购买记录</div>
    <ul class="my-table">
      <li class="table-th">
        <span class="table-td" style="width: 60px">id</span>
        <span class="table-td" style="width: 182px">订单编号</span>
        <span class="table-td" style="width: 182px">产品名称</span>
        <span class="table-td" style="width: 182px">下单时间</span>
        <span class="table-td" style="width: 100px">产品类型</span>
        <span class="table-td" style="width: 100px">原价</span>
        <span class="table-td" style="width: 100px">订单状态</span>
        <span class="table-td" style="width: 100px">操作</span>
        <!-- <span class="table-td" style="width: 100px">操作</span> -->
      </li>
      <li class="table-tr" v-for="item in orderList.data" :key="item.id">
        <span class="table-td" style="width: 60px">{{item.id}}</span>
        <span class="table-td" style="width: 182px">{{item.order_no}}</span>
        <span class="table-td" style="width: 182px">{{item.shop_name}}</span>
        <span class="table-td" style="width: 182px">{{item.created_at}}</span>
        <span class="table-td" style="width: 100px">{{item.order_type}}</span>
        <span class="table-td" style="width: 100px">￥{{item.price}}</span>
        <span class="table-td" style="width: 100px">{{item.order_status}}</span>
        <span class="table-td" style="width: 100px"><router-link class="link" :to="item.shop_type==0 ? '/recordedInfo/'+item.shop_id : '/seriesLessonsInfo/'+item.shop_id">查看</router-link></span>
        <!-- <span class="table-td" style="width: 100px"><router-link :to="'/userCenter/purchaseRecords/info/'+item.id" class="ope">详情</router-link></span> -->
      </li> 
    </ul>
  </div>
</template>
<script>
export default {
  name: 'PurchaseRecords',
  data(){
    return {
      orderList: '',  //记录
    }
  },
  mounted(){
    this.getOrderList(); //获取记录
  },
  methods: {
    //获取记录
    getOrderList(){
      this.$axios.post(`/v1/personal/orderList`, {
        page: 1,
        pre_page: 2,
      },
      {useLog: true}).then(res => {
        this.orderList = res.data;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.my-table{
  width: 100%;
  .table-th,
  .table-tr{
    height: 56px;
  }
  .table-td{
    font-size: 12px;
  }
}
.ope{
  font-size: 12px;
  color: #254ED4
}
.link{
  color: #254ED4;
}
</style>
